import ProductCreate from 'i#/product/composite/create.vue'
import ProductUpdate from 'i#/product/composite/edit.vue'

export default {
    name      : "index",
    components: {
        ProductCreate,
        ProductUpdate
    },
    data() {
        return {
            unBoxData           : {
                name : '',
                forms: {
                    errors: {}
                }
            },
            items               : {},
            loading             : false,
            searchWarehouse     : null,
            warehouses          : [],
            options             : {
                sortBy      : ['name'],
                sortDesc    : [false],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            dialog              : false,
            createDialog: false,
            updateDialog: false,
            edit_id             : null,
            headers             : [
                {text: 'name', value: 'name', sortable: true},
                {text: 'number of items', value: 'bill_of_materials_count', sortable: true},
                {text: 'cost of goods', value: 'buying_price', sortable: true},
                {text: 'selling price', value: 'price', sortable: true},
                {text: 'weight/unit', value: 'weight', sortable: false},
                {text: 'created at', value: 'created_at', sortable: true},
                {text: 'action', value: 'action', sortable: false},
            ],
            closeOnContentClick : false
        }
    },
    watch     : {
        unBoxData:{
            deep:true,
            handler(val){
                if(val.forms){
                    if(val.forms.location){
                        let arr = val.forms.location.split('-')
                        if (arr.length) val.forms.warehouse = parseInt(arr[1])
                    }
                }
            }
        },
        dialog(val) {
            if(!val) {
                this.unBoxData.forms = {
                    errors: {}
                }
            }
        },
        searchWarehouse(val) {
            this.loading = true
            axios.get('/api/inventory/warehouses?dropdown=true')
                 .then(response => {
                     this.warehouses = response.data;
                     this.loading    = false
                 }).catch(error => {

            })
        },
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        }
    },
    created() {
        axios
            .get('/api/get-warehouse')
            .then((result) => {
                this.warehouses = result.data
            }).catch((err) => {

        });
    },
    methods   : {
        postUnbox() {
            this.unBoxData.forms.errors = {}
            axios
                .post('/api/inventory/product-composite-unbox', this.unBoxData.forms)
                .then((result) => {
                    Swal.fire({
                                  icon : 'success',
                                  text : result.data.message,
                                  timer: 3000
                              })
                        .then((result) => {
                            this.getData()
                            this.dialog = false
                        })
                }).catch(error => {
                if(error.response.status === 422) {
                    this.unBoxData.forms.errors = error.response.data.errors
                } else {
                    Swal.fire({
                                  icon: 'error',
                                  text: error.response.data.message
                              })
                }
                this.loading = false
            });
        },
        showUnboxDialog(item) {
            this.dialog               = true
            this.unBoxData.warehouses = item.warehouses
            this.unBoxData.name       = item.name
            this.unBoxData.forms.id   = item.id
        },
        showUnboxButton(item) {
            if(_.isEmpty(item.warehouses)) {
                return false
            }
            return _.sumBy(item.warehouses, (i) => {
                return i.pivot.quantity
            })
        },
        deleteItem(id) {
            Swal.fire({
                          icon             : 'warning',
                          text             : 'Are you sure you want to delete this item?',
                          showCancelButton : true,
                          showConfirmButton: true,
                      }).then(result => {
                if(result.isConfirmed) {
                    this.loading = true
                    axios.delete('/api/inventory/products/' + id)
                         .then(res => {
                             this.getData()
                             this.loading = false
                             Swal.fire({
                                           toast            : true,
                                           icon             : res.data.type,
                                           timer            : 3000,
                                           text             : res.data.message,
                                           showCancelButton : false,
                                           showConfirmButton: false
                                       })
                         })
                }
            })


        },
        getData: _.debounce(function () {
            axios.get('/api/inventory/product-composite', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data.products
                     this.loading = false
                 })
        }, 400),
    }
}
